import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Контакти | Remartastyle Sport Pub
			</title>
			<meta name={"description"} content={"Трансляції найпопулярніших спортивних подій, найпопулярніші закуски та найбільший асортимент пива в місті. Проведи вечір незабутньо."} />
			<meta property={"og:title"} content={"Контакти | Remartastyle Sport Pub"} />
			<meta property={"og:description"} content={"Трансляції найпопулярніших спортивних подій, найпопулярніші закуски та найбільший асортимент пива в місті. Проведи вечір незабутньо."} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://images.unsplash.com/photo-1547619292-240402b5ae5d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80)"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="40%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Завітайте до нас
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								вул. Сумська 35, Харків, 61000
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email{" "}
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:contact@remartastyle.net" text-decoration="none" hover-text-decoration="underline" color="--light">
									contact@remartastyle.net
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Телефон
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+38 (090) 430-11-23
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="55%" padding="8px 8px 8px 8px" md-width="100%">
					<Box>
						<Box
							padding="56px 48px"
							margin="0 0 0 auto"
							md-max-width="100%"
							background="--color-primary"
							max-width="360px"
						>
							<Text as="h3" font="--headline3" margin="0 0 20px 0">
								Написати повідомлення
							</Text>
							<Formspree endpoint="xeqpgrlv">
								<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text margin="0 0 4px 0" font="--base">
												Ім'я
											</Text>
											<Input max-width="400px" width="100%" name="name" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Email
											</Text>
											<Input max-width="400px" width="100%" type="email" name="email" />
										</Box>
									</Box>
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Повідомлення
											</Text>
											<Input width="100%" name="message" as="textarea" rows="4" />
										</Box>
									</Box>
									<Components.QuarklycommunityKitCheckbox>
										<Override slot="Text" font="12px/1.1 -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif">
											Я ознайомлений з{" "}
											<Link href="/politika" text-decoration="none" hover-text-decoration="underline" color="--light">
												Політикою конфіденційності
											</Link>
											, знаю і погоджуюсь, що мої данні будуть оброблені.
										</Override>
										<Override slot="Input" width="30px" />
									</Components.QuarklycommunityKitCheckbox>
									<Box width="100%" padding="8px 18px 8px 18px">
										<Box display="flex" flex-direction="column" align-items="flex-start">
											<Button background="--color-dark">
												Відправити
											</Button>
										</Box>
									</Box>
								</Box>
							</Formspree>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
	</Theme>;
});